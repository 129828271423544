import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import { checkMark as ReactComponent } from "./images/checkmark.svg";
import axios from "axios"

class App extends Component {
  state = {
    submitted: false,
    type: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    errorFirstName: false,
    errorLastName: false,
    errorType: false,
    errorEmail: false
  };

  handleClick = e => {
    this.setState({
      errorFirstName: false,
      errorLastName: false,
      errorType: false,
      errorEmail: false
    });

    if (this.state.firstName === "") {
      this.setState({
        errorFirstName: true
      });
    }

    if (this.state.lastName === "") {
      this.setState({
        errorLastName: true
      });
    }

    if (this.state.email === "") {
      this.setState({
        errorEmail: true
      });
    }

    if (this.state.type === "") {
      this.setState({
        errorType: true
      });
    }

    if (
      this.state.type &&
      this.state.email &&
      this.state.lastName &&
      this.state.firstName
    ) {
      axios.post("https://my.gogig.com:4000/api/Purdues", {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phoneNumber: this.state.phoneNumber,
        reason: this.state.type,
        email: this.state.email
      })
      window.scrollTo(0, 0)
      this.setState({
        submitted: true
      });
    }
  };

  handleChange = e => {
    let { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  render() {
    return (
      <div>
        <div className="header">
          <div
            style={{
              maxWidth: 800,
              marginLeft: "auto",
              marginRight: "auto"
            }}
          >
            <div>
              <img
                src={require("./images/logo.png")}
                style={{
                  maxWidth: 150,
                  position: "relative",
                  top: 20,
                  left: 20
                }}
              />
              <p
                style={{
                  color: "#fff",
                  position: "relative",
                  top: 40,
                  right: 88,
                  display: "inline-block",
                  fontSize: 12
                }}
              >
                CAREER CONNECT
              </p>
            </div>
            {this.state.submitted ? null : (
              <a href="#signupForm">
                <button className="signup-btn">Sign Up</button>
              </a>
            )}
          </div>
        </div>
        <div
          style={{
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${require("./images/purdue.png")})`,
            height: "100%",
            padding: 20,
            backgroundSize: "cover"
          }}
        >
          {this.state.submitted ? (
            <div
              style={{
                maxWidth: 800,
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                zIndex: "100",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                height: 520
              }}
            >
              <img
                style={{
                  width: 100,
                  height: 100,
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: 20
                }}
                src={require("./images/checkmark.svg")}
              />
              <p
                style={{
                  color: "#fff",
                  textAlign: "center",
                  margin: 0,
                  fontWeight: "bold",
                  fontSize: 36
                }}
              >
                THANK YOU FOR SIGNING UP!
              </p>
              <p
                style={{
                  color: "#fff",
                  textAlign: "center",
                  margin: 0,
                  marginTop: 20,
                  fontSize: 20
                }}
              >
                You will be notified when Career Connect becomes available! In
                the meantime, check your inbox for news and updates!
              </p>
            </div>
          ) : (
            <div
              style={{
                maxWidth: 800,
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                zIndex: "100"
              }}
            >
              <h1 className="title">
                Introducing Purdue Alumni Career Connect
              </h1>
              <p className="txt">
                The Purdue Alumni Association is partnering with GoGig to create
                an alumni career advancement portal designed for the more
                experienced professional. Unlike traditional job board sites,
                you can remain anonymous to connect with companies that love to
                hire Boilermakers.
              </p>
              <p className="txt">
                Ways Purdue Alumni Career Connect can benefit you:
              </p>
              <ul>
                <li>
                  If you are a Purdue graduate and already have a job, you can
                  anonymously entertain conversations about potential career
                  advancement without jeopardizing your current role
                </li>
                <li>
                  Have real conversations with hiring companies that express
                  interest in you — no résumé required
                </li>
                <li>
                  If you are a protected class citizen, anonymity in the
                  platform will remove all implicit bias and discrimination in
                  the hiring process
                </li>
                <li>
                  Stay informed about all things associated with the Purdue
                  Alumni Association
                </li>
              </ul>
              <p className="txt">
                Does your company love to hire Purdue graduates? Now you can
                connect with experienced Boilermakers through Purdue Alumni
                Career Connect!
              </p>
              <p className="txt">Coming soon in 2021</p>

              <div id="signupForm">
                <p className="form-heading">
                  Sign Up To Get Notified When Career Connect Is Available. It's
                  Free.
                </p>
                {/* <p className="form-heading">IT'S FREE.</p> */}
                <div
                  style={{
                    display: "flex"
                  }}
                >
                  <input
                    style={{
                      flex: 1,
                      border: this.state.errorFirstName
                        ? "2px solid red"
                        : "1px solid #c4bfc0"
                    }}
                    name="firstName"
                    onChange={this.handleChange}
                    className="txtInput"
                    type="textbox"
                    placeholder="First Name"
                  />
                  <input
                    style={{
                      flex: 1,
                      border: this.state.errorLastName
                        ? "2px solid red"
                        : "1px solid #c4bfc0"
                    }}
                    onChange={this.handleChange}
                    name="lastName"
                    className="txtInput"
                    type="textbox"
                    placeholder="Last Name"
                  />
                </div>
                <div
                  style={{
                    display: "flex"
                  }}
                >
                  <input
                    style={{
                      maxWidth: 750,
                      border: this.state.errorEmail
                        ? "2px solid red"
                        : "1px solid #c4bfc0"
                    }}
                    onChange={this.handleChange}
                    name="email"
                    className="txtInput"
                    type="email"
                    placeholder="Email Address"
                    id="email"
                  />
                </div>
                <div
                  style={{
                    display: "flex"
                  }}
                >
                  <input
                    style={{
                      maxWidth: 750
                    }}
                    id="phoneNumber"
                    className="txtInput"
                    type="tel"
                    placeholder="Phone Number"
                  />
                </div>
                <div
                  style={{
                    display: "flex"
                  }}
                >
                  <select
                    id="sel"
                    onChange={this.handleChange}
                    name="type"
                    className="slect"
                    style={{
                      maxWidth: 784,
                      border: this.state.errorType
                        ? "2px solid red"
                        : "1px solid #c4bfc0"
                    }}
                  >
                    <option value="">
                      Please select an option
                    </option>
                    <option value="student">
                      I'm a Purdue student and want to use this to help me
                      connect to potential career opportunities
                    </option>
                    <option value="alumni">
                      I'm a Purdue alumni and want to use this to help me
                      connect to potential career opportunities
                    </option>
                    <option value="recruiter">
                      I'm interested in using Purdue Alumini Career Connect to
                      hire Purdue alumni
                    </option>
                    <option value="other">Other (please share)</option>
                    <optgroup label=""></optgroup>
                  </select>
                </div>
                {this.state.type === "other" && (
                  <div
                    style={{
                      display: "flex"
                    }}
                  >
                    <textarea
                      className="txtarea"
                      placeholder="Please share"
                      type="textarea"
                      row="30"
                    />
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <button onClick={this.handleClick} className="signUp">
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            background: "#000",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingBottom: 20,
            paddingTop: 20
          }}
        >
          <p
            style={{
              color: "#fff",
              textAlign: "center",
              margin: 0,
              padding: 20
            }}
          >
            CONNECT WITH US
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <a href="https://www.facebook.com/PurdueAlumniAssociation ">
              <img
                style={{
                  marginLeft: 10,
                  marginRight: 10
                }}
                src={require("./images/fb.png")}
              />
            </a>
            <a href="https://www.twitter.com/PurdueAlumni">
              <img
                style={{
                  marginLeft: 10,
                  marginRight: 10
                }}
                src={require("./images/twit.png")}
              />
            </a>
            <a href="https://www.instagram.com/purduealumni/">
              <img
                style={{
                  marginLeft: 10,
                  marginRight: 10
                }}
                src={require("./images/inst.png")}
              />
            </a>
            <a href="https://purduealumniassociation.com/">
              <img
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  position: "relative",
                  bottom: 5
                }}
                src={require("./images/gradu.png")}
              />
            </a>
            <a href="https://www.linkedin.com/company/purdue-alumni-association">
              <img
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  position: "relative",
                  bottom: 10
                }}
                src={require("./images/linkedin.png")}
              />
            </a>
          </div>
        </div>
        <div
          style={{
            background: "#555960",
            width: "100%",
            height: 75,
            paddingTop: 20
          }}
        >
          <p
            style={{
              margin: 0,
              color: "#fff",
              textAlign: "center",
              padding: 20
            }}
          >
            Powered by GoGig
          </p>
        </div>
      </div>
    );
  }
}

export default App;
